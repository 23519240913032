import React, { useEffect, useState } from "react";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as BulkIcon } from "assets/icons/bulk.svg";
import { ReactComponent as UserAddActiveIcon } from "assets/icons/user-add-active.svg";
import { ReactComponent as UserAddIcon } from "assets/icons/user-add.svg";
import { ReactComponent as UserArchiveActiveIcon } from "assets/icons/user-minus-active.svg";
import { ReactComponent as UserArchiveIcon } from "assets/icons/user-minus.svg";
import { TextInput, Dropdown, PortalTooltip } from "components";
import Card from "../../components/Card";
import {
  getAllEmployees,
  updateArchiveInBulkEmployees,
} from "../../slices/employeeSlice";
import { periodConverter } from "utils";
import ArchiveBulk from "./ArchiveBulk";
import ArchiveBulkConfirmation from "./ArchiveBulkConfirmation";
import styles from "./EmployeeList.module.sass";
import MergeBulk from "./MergeBulk";
import NoResult from "./NoResult";
import Table from "./Table";

const EmployeeList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { employeeList, employeeListLoading } = useSelector(
    (state) => state.employee
  );

  // Retrieve the selected filter interval and date range from the global state
  const { selectedFilterInterval } = useSelector((state) => state.app);
  const { integrationStartDate, integrationEndDate } = useSelector(
    (state) => state.employee
  );

  const [search, setSearch] = useState("");
  const [bulkModal, setBulkModal] = useState(false);
  const [mergeModal, setMergeModal] = useState(false);
  const [sorting, setSorting] = useState({
    sort_by: "name",
    sort_order: "asc",
  });
  const [sortLoading, setSortLoading] = useState(false);
  const [archiveConfirmation, setArchiveConfirmation] = useState(false);

  useEffect(() => {
    if (integrationStartDate && integrationEndDate) {
      // Dispatch getAllEmployees with date range parameters
      dispatch(
        getAllEmployees({
          startDate: integrationStartDate,
          endDate: integrationEndDate,
          sorting,
        })
      );
    } else {
      // Dispatch getAllEmployees with the selected filter interval
      dispatch(
        getAllEmployees({
          period: periodConverter(selectedFilterInterval),
          sorting,
        })
      );
    }
  }, [
    dispatch,
    sorting,
    integrationStartDate,
    integrationEndDate,
    selectedFilterInterval,
  ]);

  const onSortHandler = async (sort_by) => {
    if (!sort_by) return;

    setSortLoading(true);
    let sort_order = "desc";
    if (sorting?.sort_by === sort_by) {
      sort_order = sorting?.sort_order === "asc" ? "desc" : "asc";
    }

    dispatch(
      getAllEmployees({
        sort_by,
        sort_order,
        ...(integrationStartDate && integrationEndDate
          ? { startDate: integrationStartDate, endDate: integrationEndDate }
          : { period: periodConverter(selectedFilterInterval) }),
      })
    );

    setSorting({ sort_by, sort_order });
    setSortLoading(false);
  };

  const handleArchive = async () => {
    const action = dispatch(
      updateArchiveInBulkEmployees({
        employees: selectedEmployees.map(({ id }) => id),
      })
    );
    setArchiveConfirmation(false);
    toast.promise(
      action,
      {
        pending: "Archiving Employee...",
        success: {
          render() {
            dispatch(getAllEmployees());
            setSelectedEmployees([]);
            setSelectedEmployeesObject({});
            return `Employee archived successfully!`;
          },
        },
        error: "Failed to archive Employees!",
      },
      {
        autoClose: 1500,
        position: toast.POSITION.BOTTOM_RIGHT,
      }
    );
  };

  useEffect(() => {
    dispatch(getAllEmployees());
  }, [dispatch]);

  const filterByTagsAndSectionTagsAndSearch = (employeeList || [])?.filter(
    ({ section_tags_array, tags_array, name }) =>
      section_tags_array.toLowerCase().includes(search) ||
      tags_array.toLowerCase().includes(search) ||
      name.toLowerCase().includes(search)
  );

  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectedEmployeesObject, setSelectedEmployeesObject] = useState({});

  const head = (
    <div className={styles.headActions}>
      <TextInput
        className={styles.search}
        placeholder="Search by employee, team or tag"
        onChange={(e) => setSearch(e.target.value.toLowerCase())}
        value={search}
        type="text"
        name="search"
        icon="search"
      />
      <div className={cn(styles.settingButtons)}>
        <PortalTooltip
          position="top"
          text={
            <div className={styles.mergeTooltip}>
              Select one or more Employees for the merger.
            </div>
          }
        >
          <button
            className={cn(styles.settingButton, {
              [styles.activatedMerge]: selectedEmployees.length > 1,
            })}
            onClick={() => setMergeModal(true)}
            disabled={selectedEmployees.length < 2}
          >
            {selectedEmployees.length > 1 ? (
              <UserAddActiveIcon />
            ) : (
              <UserAddIcon />
            )}
            Merge
            {selectedEmployees.length > 1
              ? `(${selectedEmployees.length})`
              : ""}
          </button>
        </PortalTooltip>
        {selectedEmployees.length === 0 ? (
          <div className={styles.archiveButton}>
            <button
              className={cn(styles.settingButton, {
                [styles.activatedArchive]: selectedEmployees.length > 0,
              })}
              onClick={handleArchive}
              disabled={selectedEmployees.length < 1}
            >
              {selectedEmployees.length > 0 ? (
                <UserArchiveActiveIcon />
              ) : (
                <UserArchiveIcon />
              )}
              Archive
            </button>

            <Dropdown
              classDropdownHead={styles.dropdownHead}
              className={styles.dropdown}
              onClick={() => setBulkModal(true)}
              options={[
                <div onClick={() => setBulkModal(true)}>
                  <BulkIcon /> Bulk Archive
                </div>,
              ]}
            />
          </div>
        ) : (
          <button
            className={cn(styles.settingButton, {
              [styles.activatedArchive]: selectedEmployees.length > 0,
            })}
            onClick={() => setArchiveConfirmation(true)}
            disabled={selectedEmployees.length < 1}
          >
            {selectedEmployees.length > 0 ? (
              <UserArchiveActiveIcon />
            ) : (
              <UserArchiveIcon />
            )}
            Archive
          </button>
        )}
      </div>
    </div>
  );

  return (
    <>
      <div className={styles.headContainer}>
        <div className="title-purple">All Employees</div>
        {head}
      </div>
      <div className={styles.dataContainer}>
        <Card
          className={styles.card}
          title={null}
          classTitle={cn("title-purple", styles.title)}
          classCardHead={cn(styles.head)}
          head={null}
        >
          <div className={cn(styles.row)}>
            {(!employeeList || !employeeList.length) && (
              <NoResult
                onCreate={() => navigate("/integrations")}
                loading={employeeListLoading}
              />
            )}
            {filterByTagsAndSectionTagsAndSearch?.length > 0 && (
              <Table
                className={styles.table}
                items={filterByTagsAndSectionTagsAndSearch}
                sorting={sorting}
                onSortHandler={onSortHandler}
                loading={sortLoading}
                onRowSelectionChange={(selectedRows) => {
                  setSelectedEmployees(
                    Object.values(selectedRows).filter((x) => x)
                  );
                  setSelectedEmployeesObject(selectedRows);
                }}
                selectedRowsObj={selectedEmployeesObject}
              />
            )}
          </div>

          <ArchiveBulkConfirmation
            visible={archiveConfirmation}
            onClose={() => setArchiveConfirmation(false)}
            onConfirm={handleArchive}
            selectedEmployees={selectedEmployees}
          />
          <ArchiveBulk
            visible={bulkModal}
            onClose={() => setBulkModal(false)}
          />
          <MergeBulk
            selectedEmployees={selectedEmployees}
            visible={mergeModal}
            onClose={() => setMergeModal(false)}
            onMerge={() => {
              setSelectedEmployees([]);
              setSelectedEmployeesObject({});
            }}
          />
        </Card>
      </div>
    </>
  );
};

export default EmployeeList;
