import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import api from "../app/api";
import { timezones as staticTimezones } from "../app/data";

const structuredTimezones = staticTimezones
  .map((zone) => ({
    name: zone.zoneName,
    offset: zone.gmtOffset / 3600,
  }))
  .map(
    (zone) =>
      zone.name + ` (GMT ${zone.offset > 0 ? `+${zone.offset}` : zone.offset})`
  );

const initialState = {
  status: "idle",
  loading: false,
  error: null,
  company: null,
  users: [],
  roles: "",
  admin: null,

  timezones: structuredTimezones,
};

export const company = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCompanyInformation.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getCompanyInformation.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.company = action.payload?.data?.company || [];
      })
      .addCase(getCompanyInformation.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getCompanyUsers.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getCompanyUsers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.users = action.payload?.data?.users || [];
      })
      .addCase(getCompanyUsers.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getCompanyRoles.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getCompanyRoles.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.roles = action.payload?.data?.roles || "";
      })
      .addCase(getCompanyRoles.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(updateCompany.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(updateCompany.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
      })
      .addCase(updateCompany.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getAdminInformation.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getAdminInformation.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.admin = action.payload?.data?.user || [];
      })
      .addCase(getAdminInformation.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getTimezones.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getTimezones.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.timezones = action.payload?.data?.zones
          ? action.payload?.data?.zones
              .map((zone) => ({
                name: zone.zoneName,
                offset: zone.gmtOffset / 3600,
              }))
              .map(
                (zone) =>
                  zone.name +
                  ` (GMT ${zone.offset > 0 ? `+${zone.offset}` : zone.offset})`
              )
          : structuredTimezones;
      })
      .addCase(getTimezones.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const getCompanyInformation = createAsyncThunk(
  "getCompanyInformation",
  async (id) => {
    const response = await api.get(`/company`);
    return response;
  }
);

export const getCompanyUsers = createAsyncThunk(
  "getCompanyUsers",
  async (data) => {
    const response = await api.get(`/company/users`, data);
    return response;
  }
);

export const getCompanyRoles = createAsyncThunk(
  "getCompanyRoles",
  async (data) => {
    const response = await api.get(`/company/roles`, data);
    return response;
  }
);

export const updateCompany = createAsyncThunk("updateCompany", async (data) => {
  const response = await api.post(`/company/update`, data);
  return response;
});

// Admin Endpoints
export const getAdminInformation = createAsyncThunk(
  "getAdminInformation",
  async (id) => {
    const response = await api.get(`/company/admin`);
    return response;
  }
);

export const updateAdmin = createAsyncThunk("updateAdmin", async ({ data }) => {
  const response = await api.post(`/company/update/admin`, data);
  return response;
});

export const deleteUser = createAsyncThunk("deleteUser", async (id) => {
  const response = await api.post(`/company/delete_user`, { user_id: id });
  return response;
});

export const inviteUser = createAsyncThunk("inviteUser", async (data) => {
  const response = await api.post(`/company/invite`, data);
  return response;
});

export const getTimezones = createAsyncThunk("getTimezones", async () => {
  const response = await axios.get(
    "https://api.timezonedb.com/v2.1/list-time-zone?key=0A88F5FC513E&format=json"
  );
  return response;
});

// export const { } = tag.actions;

export default company.reducer;
