import { lazy, string, object } from "yup";

import api from "../app/api";

const signUpValidationSchema = lazy((values) =>
  object().shape({
    username: string()
      .nullable()
      .required("Username can't be blank")
      .matches(
        /^[a-zA-Z0-9_.-]*$/,
        "Invalid username. Use only letters, numbers, underscores, dots, and dashes."
      )
      .min(3, "Minimum three characters required in username")
      .test(
        "Unique Username",
        "This user name is in use, please select another one",
        (value) =>
          new Promise((resolve) => {
            api.post("auth/username", { username: value }).then((res) => {
              if (res.data.response === true) {
                resolve(false);
              }
              resolve(true);
            });
          })
      ),

    email: string()
      .nullable()
      .required("Email can't be blank")
      .min(3, "Minimum three characters required in email")
      .email("Should be a valid email")
      .test(
        "Unique Email",
        "This email address is in use, please select another one",
        (value) =>
          new Promise((resolve) => {
            api.post("auth/mail", { email: value }).then((res) => {
              if (res.data.response === true) {
                resolve(false);
              }
              resolve(true);
            });
          })
      ),

    password: string()
      .nullable()
      .required("Password can't be blank")
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Must contain 8 characters, at least 1 uppercase, 1 lowercase, 1 number and 1 special case character"
      ),

    passwordConfirmation: string()
      .nullable()
      .required("Password confirmation can't be blank")
      .test(
        "confirmation",
        "Passwords should match",
        (value) => values.password === value
      ),
  })
);
const signInValidationSchema = object().shape({
  username: string()
    .nullable()
    .required("Username can't be blank")
    .matches(
      /^[a-zA-Z0-9_.-]*$/,
      "Invalid characters in username, space is not allowed"
    )
    .min(3, "Minimum three characters required in username"),
  password: string()
    .nullable()
    .required("Password can't be blank")
    .min(3, "Minimum three characters required in password"),
});

const forgotValidationSchema = object().shape({
  username: string()
    .nullable()
    .required("Can't be blank")
    .min(3, "Minimum three characters required in username"),
});

const confirmValidationSchema = lazy(() =>
  object().shape({
    confirmation: string()
      .nullable()
      .required("Activation code can't be blank")
      .test(
        "length",
        "Tip: Activation code should be 4 chars length",
        (value) => value?.length === 6
      ),
  })
);

const changePasswordValidationSchema = lazy((values) =>
  object().shape({
    // confirmation: string()
    //   .nullable()
    //   .required("Can't be blank")
    //   .matches(
    //     /^(?<=\s|^)\d+(?=\s|$)/,
    //     "Tip: Activation code should contain only numbers"
    //   )
    //   .test(
    //     "length",
    //     "Tip: Activation code should be 4 chars length",
    //     (value) => value?.length === 6
    //   ),
    password: string()
      .nullable()
      .required("Password can't be blank")
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Must contain 8 characters, at least 1 uppercase, 1 lowercase, 1 number and 1 special case character"
      ),
    passwordConfirmation: string()
      .nullable()
      .test(
        "confirmation",
        "Passwords should match",
        (value) => values.password === value
      ),
  })
);

export {
  signUpValidationSchema,
  signInValidationSchema,
  forgotValidationSchema,
  confirmValidationSchema,
  changePasswordValidationSchema,
};
