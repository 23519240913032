import cn from "classnames";

import { Icon } from "components";

import styles from "./AccordionItem.module.sass";

const Accordion = ({
  question,
  answer,
  isOpen,
  onClick,
  className,
  secondary,
}) => {
  return (
    <div
      className={cn(styles.wrapper, className, {
        [styles.secondary]: secondary,
      })}
    >
      <div
        className={cn(styles.title, {
          [styles.open]: isOpen,
        })}
        onClick={onClick}
      >
        <p className="question-content">{question}</p>
        <Icon name="arrow-down" className={styles.icon} />
      </div>

      <div
        className={cn(styles.answer, {
          [styles.open]: isOpen,
        })}
      >
        <p className={styles.answerContent}>{answer}</p>
      </div>
    </div>
  );
};

export default Accordion;
