import React, { useState, useEffect } from "react";

import cn from "classnames";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Loader from "../../../components/Loader";
import TextInput from "../../../components/TextInput";
import useDebounce from "../../../hooks/useDebounce";
import { signUpValidationSchema } from "../../../validations/authValidations";

import styles from "./Entry.module.sass";

const Entry = ({ onConfirm, title }) => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    email: "",
    username: "",
    password: "",
    passwordConfirmation: "",
  });
  const [touchedFields, setTouchedFields] = useState({});
  const debouncedValues = useDebounce(values, 500);
  const { isLoggedIn, error, loading } = useSelector((state) => state.app);

  const validateFields = async () => {
    try {
      const nonEmptyValues = Object.keys(values).reduce((acc, key) => {
        if (values[key].trim() !== "") {
          acc[key] = values[key];
        }
        return acc;
      }, {});
      await signUpValidationSchema.validate(nonEmptyValues, { abortEarly: false });
      setErrors({});
    } catch (err) {
      const fieldErrors = err.inner.reduce((acc, curr) => {
        acc[curr.path] = curr.message;
        return acc;
      }, {});
      setErrors(fieldErrors);
    }
  };

  const validateField = async (name, value) => {
    if (value.trim() === "") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
      return;
    }

    try {
      const tempValues = { ...values, [name]: value };
      await signUpValidationSchema.validate(tempValues, { abortEarly: false });
      setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
    } catch (err) {
      const fieldErrors = err.inner.reduce((acc, curr) => {
        acc[curr.path] = curr.message;
        return acc;
      }, {});
      setErrors((prevErrors) => ({ ...prevErrors, [name]: fieldErrors[name] }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
    if (touchedFields[name]) {
      validateField(name, value);
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    setTouchedFields((prevTouchedFields) => ({ ...prevTouchedFields, [name]: true }));
    validateField(name, value);
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    if (error) {
      setErrors((prevErrors) => ({ ...prevErrors, global: error }));
    }
  }, [error]);

  useEffect(() => {
    if (!error) {
      setErrors((prevErrors) => ({ ...prevErrors, global: undefined }));
    }
  }, [error]);

  useEffect(() => {
    validateFields();
  }, [debouncedValues]);

  return (
    <>
      <div className={cn("h2", styles.title)}>{title}</div>
      <div className={styles.entry}>
        <div className={styles.body}>
          <div className={styles.info}>
            Please fill the required fields below
          </div>
          {errors.global && <div className={styles.errorNote}>{errors.global}</div>}
          
          <div className={styles.fieldContainer}>
            {touchedFields.username && errors.username && (
              <div className={styles.errorNote}>{errors.username}</div>
            )}
            <TextInput
              className={styles.field}
              name="username"
              type="text"
              placeholder="Username"
              required
              icon="profile-circle"
              value={values.username}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>

          <div className={styles.fieldContainer}>
            {touchedFields.email && errors.email && (
              <div className={styles.errorNote}>{errors.email}</div>
            )}
            <TextInput
              className={styles.field}
              name="email"
              type="email"
              placeholder="Your email"
              required
              icon="mail"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>

          <div className={styles.fieldContainer}>
            {touchedFields.password && errors.password && (
              <div className={styles.errorNote}>{errors.password}</div>
            )}
            <TextInput
              className={styles.field}
              name="password"
              type="password"
              placeholder="Password"
              required
              icon="lock"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>

          <div className={styles.fieldContainer}>
            {touchedFields.passwordConfirmation && errors.passwordConfirmation && (
              <div className={styles.errorNote}>{errors.passwordConfirmation}</div>
            )}
            <TextInput
              className={styles.field}
              name="passwordConfirmation"
              type="password"
              placeholder="Confirm Password"
              required
              icon="lock"
              value={values.passwordConfirmation}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>

          <button
            className={cn("button", styles.button, {
              disabled: Object.values(errors).some((err) => err !== undefined),
            })}
            onClick={() => onConfirm(values)}
          >
            {loading && <Loader className={styles.loader} white />}
            <span>Continue</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Entry;
