import React, { useState, useEffect } from "react";

import cn from "classnames";
import { use100vh } from "react-div-100vh";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import Cleaner from "../../components/Cleaner";
import Image from "../../components/Image";
import Loader from "../../components/Loader";
import TextInput from "../../components/TextInput";
import { login } from "../../slices/appSlice";

import styles from "./SignIn.module.sass";

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch(login(values));
  };
  const heightWindow = use100vh();
  const [values, setValues] = useState({
    username: "",
    password: "",
  });

  const valuesHandler = (e) => {
    const prevValues = { ...values };
    prevValues[e.target.name] = e.target.value;
    setValues({ ...prevValues });
  };

  const { isLoggedIn, confirmationToken, token, error, loading } = useSelector(
    (state) => state.app
  );

  useEffect(() => {
    if (isLoggedIn) {
      window.location.href = "/";
    }
    if (!isLoggedIn && confirmationToken && !token) {
      navigate("/sign-up");
    }
  }, [isLoggedIn, confirmationToken, token, navigate]);

  return (
    <div
      className={styles.login}
      style={{ minHeight: heightWindow }}
    >
      <Cleaner />
      <div className={styles.wrapper}>
        <Link
          className={styles.logo}
          to="/"
        >
          <Image
            className={styles.pic}
            src="/images/logo-dark.png"
            srcDark="/images/logo-light.png"
            alt="Core"
          />
        </Link>
        <div className={cn("h2", styles.title)}>Sign in</div>
        <div className={styles.body}>
          <div className={styles.subtitle}>
            Continue with email address or username
          </div>
          {error && <div className={styles.errorNote}>{error}</div>}
          <TextInput
            className={styles.field}
            name="username"
            type="email"
            placeholder="Your email/username"
            required
            icon="mail"
            onChange={(e) => valuesHandler(e)}
          />
          <TextInput
            className={styles.field}
            name="password"
            type="password"
            placeholder="Password"
            required
            icon="lock"
            onChange={(e) => valuesHandler(e)}
          />
          <button
            className={cn("button", styles.button)}
            onClick={handleSubmit}
          >
            {loading && (
              <Loader
                className={styles.loader}
                white
              />
            )}
            <span>Sign in</span>
          </button>
          <div className={styles.info}>
            Don’t have an account?{" "}
            <Link
              className={styles.link}
              to="/sign-up"
            >
              Sign up
            </Link>
          </div>
          <div className={styles.info}>
            Did you forget your password?{" "}
            <Link
              className={styles.link}
              to="/reminder"
            >
              Request code
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
