import { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./styles/app.sass";
import { Onboarding } from "components/Onboarding";
import Chat from "screens/Chat";
import HomeNew from "screens/HomeNew";
import OnboardingScreen from "screens/Onboarding";
import TeamPage from "screens/Team";
import { getCurrentUser } from "slices/appSlice";

import Page from "./components/Page";
import EmployeeDetails from "./screens/EmployeeDetails";
import LastEntries from "./screens/EmployeeDetails/AllLastEntries";
import Notes from "./screens/EmployeeDetails/AllNotes";
import EmployeeList from "./screens/EmployeeList";
import Home from "./screens/Home";
import IntegrationDetails from "./screens/IntegrationDetails";
import IntegrationLog from "./screens/IntegrationLog";
import Integrations from "./screens/Integrations";
import IntegrationSettings from "./screens/IntegrationSettings";
import Notification from "./screens/Notification";
import Reminder from "./screens/Reminder";
import SectionDetails from "./screens/SectionDetails";
import Settings from "./screens/Settings";
import SignIn from "./screens/SignIn";
import SignUp from "./screens/SignUp";
import Tags from "./screens/Tags";

function App() {
  const dispatch = useDispatch();

  window.onbeforeunload = function () {
    window.localStorage.removeItem("conversationId");
  };

  const { currentUser } = useSelector((state) => state.app);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!currentUser && token) {
      dispatch(getCurrentUser());
    }
  }, [currentUser, dispatch]);

  return (
    <BrowserRouter>
      <Onboarding />
      <Routes>
        <Route
          exact
          path="/"
          element={
            <Page innerStyle={{ paddingTop: '16px' }}>
              <HomeNew />
            </Page>
          }
        />
        <Route
          exact
          path="/home-old"
          element={
            <Page>
              <Home />
            </Page>
          }
        />
        <Route
          exact
          path="/departments/:department?/:sub?"
          element={
            <Page>
              <Home />
            </Page>
          }
        />
        <Route
          exact
          path="/team/:teamName"
          element={
            <Page>
              <TeamPage />
            </Page>
          }
        />
        <Route
          exact
          path="/integrations"
          element={
            <Page>
              <Integrations />
            </Page>
          }
        />
        <Route
          exact
          path="/employees"
          element={
            <Page>
              <EmployeeList />
            </Page>
          }
        />
        <Route
          exact
          path="/employee/:id/details"
          element={
            <Page title="Employee Details">
              <EmployeeDetails />
            </Page>
          }
        />
        <Route
          exact
          path="/teams-tags"
          element={
            <Page>
              <Tags />
            </Page>
          }
        />
        <Route
          exact
          path="/team/details/:id"
          element={
            <Page
              title=""
              isTeamDetails={true}
            >
              <SectionDetails />
            </Page>
          }
        />
        <Route
          exact
          path="/team/:teamName/:id"
          element={
            <Page
              title=""
              isTeamDetails={true}
            >
              <SectionDetails />
            </Page>
          }
        />
        <Route
          exact
          path="/tags/:tag?"
          element={
            <Page>
              <Home />
            </Page>
          }
        />
        <Route
          exact
          path="/integration/logs/:integration"
          element={
            <Page>
              <IntegrationLog />
            </Page>
          }
        />
        <Route
          exact
          path="/integration/:integration/details"
          element={
            <Page>
              <IntegrationDetails />
            </Page>
          }
        />
        <Route
          exact
          path="/notifications"
          element={
            <Page title="Notifications">
              <Notification />
            </Page>
          }
        />
        <Route
          exact
          path="/settings/:employee?"
          element={
            <Page>
              <Settings />
            </Page>
          }
        />
        <Route
          exact
          path="/integration/settings/:integration"
          element={
            <Page>
              <IntegrationSettings />
            </Page>
          }
        />
        <Route
          exact
          path="/notes/:id"
          element={
            <Page title="All Notes">
              <Notes />
            </Page>
          }
        />
        <Route
          exact
          path="employee/entries/:id"
          element={
            <Page title="Last Entries">
              <LastEntries />
            </Page>
          }
        />
        {/*<Route*/}
        {/*  exact*/}
        {/*  path="/chat/:conversationId?"*/}
        {/*  element={*/}
        {/*    <Page>*/}
        {/*      <Chat />*/}
        {/*    </Page>*/}
        {/*  }*/}
        {/*/>*/}
        <Route
          exact
          path="/sign-up"
          element={<SignUp />}
        />
        <Route
          exact
          path="/reminder"
          element={<Reminder />}
        />
        <Route
          exact
          path="/sign-in"
          element={<SignIn />}
        />
        <Route
          exact
          path="/onboarding"
          element={<OnboardingScreen />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
