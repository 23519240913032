import React, { useState, useEffect } from "react";

import cn from "classnames";
import { use100vh } from "react-div-100vh";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Avatar from "../../components/Avatar";
import Image from "../../components/Image";
import { register, confirm, logOut } from "../../slices/appSlice";

import Code from "./Code";
import Entry from "./Entry";
import styles from "./SignUp.module.sass";

const items = [
  "Integration metrics",
  "Full author options",
  "Employee metrics",
];

const SignUp = () => {
  const dispatch = useDispatch();

  const handleClearTokens = () => {
    dispatch(logOut());
  };

  const [visible, setVisible] = useState(true);
  const heightWindow = use100vh();

  const { confirmationToken } = useSelector((state) => state.app);
  useEffect(() => {
    if (confirmationToken) {
      setVisible(false);
    }
  }, [confirmationToken]);

  const handleSubmit = (values) => {
    dispatch(register(values));
  };

  const handleConfirm = (confirmer) => {
    dispatch(confirm({ confirmer, confirmationToken }));
  };
  return (
    <div className={styles.row}>
      <div className={styles.col}>
        <div className={styles.wrap}>
          <div className={cn("h4", styles.subtitle)}>Plan includes</div>
          <ul className={styles.list}>
            {items?.map((x, index) => (
              <li key={index}>{x}</li>
            ))}
          </ul>
        </div>
      </div>
      <div
        className={styles.col}
        style={{ minHeight: heightWindow }}
      >
        <div className={styles.head}>
          <Link
            className={styles.logo}
            to="/"
          >
            <Image
              className={styles.pic}
              src="/images/logo-dark.png"
              srcDark="/images/logo-light.png"
              alt="Core"
            />
          </Link>
          <div
            className={styles.info}
            onClick={handleClearTokens}
          >
            Already a member?{" "}
            <Link
              className={styles.link}
              to="/sign-in"
            >
              Sign in
            </Link>
          </div>
        </div>
        <div className={styles.wrapper}>
          {visible ? (
            <Entry
              onConfirm={(values) => handleSubmit(values)}
              title="Sign Up"
            />
          ) : (
            <Code
              title="Confirmation"
              onConfirm={(confirmer) => handleConfirm(confirmer)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SignUp;
