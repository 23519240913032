import React, { forwardRef } from "react";

import cn from "classnames";

import Icon from "../Icon";

import styles from "./TextInput.module.sass";

const TextInput = forwardRef(
  (
    {
      className,
      classLabel,
      classInput,
      label,
      icon,
      copy,
      currency,
      tooltip,
      place,
      error,
      ...props
    },
    ref
  ) => {
    return (
      <div
        className={cn(
          styles.field,
          { [styles.fieldIcon]: icon },
          { [styles.fieldCopy]: copy },
          { [styles.fieldCurrency]: currency },
          className
        )}
      >
        {label && (
          <div className={cn(classLabel, styles.label)}>
            {label}{" "}
            {error && <span className={styles.errorText}>Error: {error}</span>}
          </div>
        )}
        <div className={styles.wrap}>
          <input
            className={cn(classInput, styles.input, { [styles.error]: error })}
            {...props}
            ref={ref}
          />
          {icon && (
            <div className={styles.icon}>
              <Icon
                name={icon}
                size="24"
              />{" "}
            </div>
          )}
          {copy && (
            <button className={styles.copy}>
              <Icon
                name="copy"
                size="24"
              />{" "}
            </button>
          )}
          {currency && <div className={styles.currency}>{currency}</div>}
        </div>
      </div>
    );
  }
);

export default TextInput;
