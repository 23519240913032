import React, { useState, useEffect } from "react";

import { use100vh } from "react-div-100vh";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Image from "../../components/Image";
import { confirmReminder, requestReminder } from "../../slices/appSlice";

import Code from "./Code";
import Entry from "./Entry";
import styles from "./Reminder.module.sass";

const Reminder = () => {
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(true);
  const heightWindow = use100vh();

  const { confirmationToken } = useSelector((state) => state.app);
  useEffect(() => {
    if (confirmationToken) {
      setVisible(false);
    }
  }, [confirmationToken]);

  const handleSubmit = (values) => {
    dispatch(requestReminder(values));
  };

  const handleConfirm = (confirmer) => {
    dispatch(confirmReminder({ confirmer, confirmationToken }));
  };

  return (
    <div
      className={styles.login}
      style={{ minHeight: heightWindow }}
    >
      <div className={styles.wrapper}>
        <Link
          className={styles.logo}
          to="/"
        >
          <Image
            className={styles.pic}
            src="/images/logo-dark.png"
            srcDark="/images/logo-light.png"
            alt="Core"
          />
        </Link>
        <div className={styles.wrapper}>
          {visible ? (
            <Entry
              onConfirm={(values) => handleSubmit(values)}
              title="Remind"
            />
          ) : (
            <Code
              title="Confirmation"
              onConfirm={(confirmer) => handleConfirm(confirmer)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Reminder;
