import React, { useState } from "react";

import cn from "classnames";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { ReactComponent as AddCircled } from "assets/icons/add-circled.svg";
import { ReactComponent as ArchiveIcon } from "assets/icons/archive.svg";
import { Avatar, Tag, Checkbox } from "components";
import DeleteModal from "screens/Settings/DeleteModal";
import { getAllEmployees, removeEmployee } from "slices/employeeSlice";
import { textToArrayFormatter } from "utils";

import EmployeeToSectionModal from "./EmployeeToSectionModal";
import EmployeeToTagModal from "./EmployeeToTagModal";
import styles from "./Row.module.sass";

const Row = ({
  item,
  activeId,
  showActivity = false,
  onRowSelect,
  isRowSelected,
}) => {
  const [showSectionModal, setShowSectionModal] = useState(false);
  const [showTagModal, setShowTagModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [errorDeleting, setErrorDeleting] = useState(false);

  const [selectedUser, setSelectedUser] = useState(null);

  const dispatch = useDispatch();

  let temp_tags = textToArrayFormatter(item?.tags_array);
  let temp_emails = textToArrayFormatter(item?.emails_array);

  const handleDelete = async () => {
    const res = await dispatch(removeEmployee(item.id));
    if (res.error) setErrorDeleting(true);
    else {
      toast.success("Successfully Archived Employee!", {
        autoClose: 1500,
        position: toast.POSITION.BOTTOM_RIGHT,
      });

      setShowDeleteModal(false);

      dispatch(getAllEmployees());
    }
  };

  return (
    <>
      <div
        className={cn(styles.row, {
          [styles.selected]: activeId === item.id,
          [styles.highlited]: isRowSelected,
        })}
      >
        <div className={cn(styles.col, styles.avatarCol)}>
          <Checkbox
            className={styles.checkbox}
            value={isRowSelected}
            onChange={() => onRowSelect(item)}
          />
          <Link
            className={styles.item}
            to={`/employee/${item.id}/details`}
          >
            <div className={styles.avatar}>
              <Avatar
                src={item.avatar_url}
                alt="Avatar"
              />
            </div>
            <div className={styles.details}>
              <div className={styles.user}>{item.name}</div>
              <div className={styles.email}>
                {temp_emails?.split(",")?.map((email) => (
                  <div key={email}>{email}</div>
                ))}
              </div>
            </div>
          </Link>
        </div>
        {showActivity && (
          <div className={styles.col}>
            <div className={cn(styles.tableTags, styles.activityCol)}>
              <div>{item.sum_activities}</div>
            </div>
          </div>
        )}
        <div className={cn(styles.col, styles.teamsCol)}>
          <div className={cn(styles.tableTags, styles.tagCol)}>
            <div
              className={cn(styles.addButton, {
                [styles.show]: eval(item?.section_tags_array)?.length <= 1,
              })}
            >
              <button
                className={cn(
                  "button button-small button-stroke-blue",
                  styles.button
                )}
                onClick={() => {
                  setShowSectionModal(true);
                  setSelectedUser(item);
                }}
              >
                {eval(item?.section_tags_array)?.length > 0 ? (
                  <AddCircled />
                ) : (
                  "Add to a Team"
                )}
              </button>
            </div>
            {eval(item?.section_tags_array)?.map((tag, index) => {
              if (!tag || tag === "All") return null;
              return (
                <Tag
                  key={index}
                  name={tag}
                  variant={3}
                  redirect={`/departments/${tag}`}
                />
              );
            })}
          </div>
        </div>
        <div className={cn(styles.col, styles.tagsCol)}>
          <div className={cn(styles.tableTags, styles.tagCol)}>
            <div
              className={cn(styles.addButton, {
                [styles.show]: temp_tags.length === 0,
              })}
            >
              <button
                className={cn(
                  "button button-small button-stroke-blue",
                  styles.button
                )}
                onClick={() => {
                  setShowTagModal(true);
                  setSelectedUser(item);
                }}
              >
                {temp_tags.length > 0 ? <AddCircled /> : "Add to a Tag"}
              </button>
            </div>
            {temp_tags.length > 0 &&
              temp_tags?.split(",")?.map((tag) => {
                if (tag.length < 3) {
                  return (
                    <Tag
                      key={tag}
                      name={tag}
                      variant={6}
                      redirect={`/tags/${tag}`}
                    />
                  );
                } else if (tag.length >= 3 && tag.length < 6) {
                  return (
                    <Tag
                      key={tag}
                      name={tag}
                      variant={6}
                      redirect={`/tags/${tag}`}
                    />
                  );
                } else if (tag.length >= 6 && tag.length < 9) {
                  return (
                    <Tag
                      key={tag}
                      name={tag}
                      variant={9}
                      redirect={`/tags/${tag}`}
                    />
                  );
                } else if (tag.length >= 9 && tag.length < 12) {
                  return (
                    <Tag
                      key={tag}
                      name={tag}
                      variant={12}
                      redirect={`/tags/${tag}`}
                    />
                  );
                } else if (tag.length >= 12 && tag.length < 15) {
                  return (
                    <Tag
                      key={tag}
                      name={tag}
                      variant={15}
                      redirect={`/tags/${tag}`}
                    />
                  );
                } else if (tag.length >= 15 && tag.length < 18) {
                  return (
                    <Tag
                      key={tag}
                      name={tag}
                      variant={18}
                      redirect={`/tags/${tag}`}
                    />
                  );
                } else {
                  return (
                    <Tag
                      key={tag}
                      name={tag}
                      variant={21}
                      redirect={`/tags/${tag}`}
                    />
                  );
                }
              })}
          </div>
        </div>
        <div className={cn(styles.col, styles.trashContainer)}>
          <ArchiveIcon
            className={styles.trash}
            onClick={() => {
              setSelectedUser(item);
              setShowDeleteModal(true);
            }}
          />
        </div>
      </div>

      {showSectionModal && (
        <EmployeeToSectionModal
          visible={showSectionModal}
          onClose={() => {
            setShowSectionModal(false);
            setSelectedUser(null);
          }}
          user={selectedUser}
        />
      )}

      {showTagModal && (
        <EmployeeToTagModal
          visible={showTagModal}
          onClose={() => {
            setShowTagModal(false);
            setSelectedUser(null);
          }}
          user={selectedUser}
        />
      )}

      {showDeleteModal && (
        <DeleteModal
          visible={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onConfirm={handleDelete}
          errorDeleting={errorDeleting}
        />
      )}
    </>
  );
};

export default Row;
